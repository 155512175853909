@import "src/styles/variables";

.checkbox {
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

.checkbox label {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: $color_yellow_dull;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  margin-right: 10px;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid $color_yellow_full;
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

label .checkmark::after {
  font-size: 33px;
  text-align: center;
  content: url("/static/icons/check_mark.svg");
  background-color: $color_yellow_full;
  width: 100%;
  height: 100%;
  line-height: 0;
}

label input:checked ~ .checkmark::after {
  display: block;
}
