@import "src/styles/variables";

.form {
  margin-top: 24px;
}

.checkboxInfo {
  color: $color_yellow_dull;
  margin-bottom: 24px;
}

.button {
  margin-bottom: 24px;
}

.link {
  text-align: center;
  margin-bottom: 18px;
  color: $color_yellow_full;
}
