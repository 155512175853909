@import "src/styles/variables";

.textField {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border-bottom: 1px solid $color_yellow_full;

  .labelBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      color: $color_yellow_dull;
    }

    p {
      color: $color_red;
      font-style: italic;
      font-weight: 800;
    }
  }

  .inputBox {
    display: flex;
    align-items: center;

    input {
      border-radius: 0;
      outline: none;
      font-size: 18px;
      height: 40px;
      border: none;
      color: $color_white;
      background: transparent;
      flex: 1;
      padding-right: 10px;
    }
  }
}
