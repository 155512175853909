@import "src/styles/variables";

.disclaimer {
  background-color: $color_gray;
  padding: 9px 20px;
  margin-top: 20px;
  color: $color_white;
  text-align: center;

  &.error {
    background-color: $color_red;
  }
}
