@import "src/styles/variables";

.errorIcon {
  display: inline-block;
  width: 21px;
  height: 21px;
  background: $color_red;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: $color_gray_dark;
}
