@import "src/styles/variables";

.form {
  margin-top: 24px;
}

.link {
  text-align: center;
  margin-top: 24px;

  a {
    text-decoration: none;
    color: $color_yellow_full;

    &.disabled {
      pointer-events: none;
    }
  }
}
