@import "src/styles/variables";

.phoneNumberField {
  display: flex;
  margin-bottom: 24px;
  align-items: flex-end;
}

.textField {
  margin-bottom: 0;
}

.countryBox {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 64px;
  height: 42px;

  &.disabled {
    &::after {
      display: none;
    }
  }

  &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $color_white;
    transform: translateX(-50%);
    right: 2px;
  }

  .flag {
    display: flex;
    height: 42px;
    width: 42px;
    transition-delay: 1s;
    opacity: 1;
    position: relative;
  }

  .select {
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: none;
    z-index: 1;
  }
}
